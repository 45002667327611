import React from 'react';
import PropTypes from 'prop-types';
import { HomepageContainer } from './homepage.css';

const HomepageBox = ({ children }) => <HomepageContainer>{children}</HomepageContainer>;

HomepageBox.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HomepageBox;
