import React from 'react';
import PropTypes from 'prop-types';
import Warp from 'warpjs';

const logoStyle = {
  maxHeight: '120px',
  maxWidth: '80%',
  margin: '0 auto 10px',
};

class WarpLogo extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.warp = undefined;
    this.object = undefined;

    this.yaxisScale = 2;
    this.rate = 0;
  }

  animate() {
    if (this.warp) {
      this.warp.transform(([x, y, oy]) => [x, oy + this.yaxisScale * Math.sin(x / 16 + this.rate), oy]);
      this.rate = this.rate + 0.05;
    }
    requestAnimationFrame(this.animate.bind(this));
  }

  startAnimation() {
    this.object = this.object || document.querySelector('object');
    const svgDoc = this.object.contentDocument;
    const svg = svgDoc.querySelector('svg');
    if (svg) {
      this.warp = new Warp(svg);
      this.warp.transform(([x, y]) => [x, y, y]);
      this.animate();
    }
  }

  render() {
    return (
      <object
        style={logoStyle}
        onLoad={this.startAnimation.bind(this)}
        type="image/svg+xml"
        data={this.props.data.image.publicURL}
        aria-label={this.props.data.title}
      />
    );
  }
}

WarpLogo.propTypes = {
  data: PropTypes.object.isRequired,
};

export default WarpLogo;
