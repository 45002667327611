import React from 'react';
import { FooterBug } from './footer.css';

const Footer = () => (
  <FooterBug>
    <marquee className="blink">
      {"Don't wait! Call now!"}
      <a href="mailto:hi@thirdslant.com" style={{marginLeft: '8px'}}>hi@thirdslant.com</a>
    </marquee>
  </FooterBug>
);

export default Footer;
