import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import theme from 'constants/theme';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 4rem;
  padding: 0 ${theme.gutter.desktop};
  margin: 0 0;

  ${MEDIA.TABLET`
    display: block;
    padding: 0 ${theme.gutter.mobile}
  `};
`;
