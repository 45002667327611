import styled from 'styled-components';
import theme from 'constants/theme';

export const FooterBug = styled.div`
  text-align: center;
  margin-top: 36px;
  height: 24px;
  line-height: 24px;
  font-size: 80%;
  background: ${theme.backgroundDark};
`;
