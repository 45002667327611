import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import { Title, Copy } from './item.css';

const Item = ({ title, copy, link, image }) => (
  <figure>
    <Link to={link}>
      <Img
        fluid={image ? image.childImageSharp.fluid : {}}
        alt={title}
        style={{height: '160px', maxHeight: '160px'}}
        imgStyle={{objectFit: 'contain'}}
      />
    </Link>
    <figcaption>
      <Link className="heading" to={link}>
        <Title>{title}</Title>
      </Link>
      <Copy>{copy}</Copy>
    </figcaption>
  </figure>
);

Item.propTypes = {
  title: PropTypes.string,
  copy: PropTypes.string,
  link: PropTypes.string,
  image: PropTypes.object.isRequired,
};

export default Item;
