import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import HomepageBox from 'components/box/homepage';
import Title from 'components/title';
import Gallery from 'components/gallery';
import WarpLogo from 'components/logo/warplogo';
import Footer from 'components/footer';

import { graphql } from 'gatsby';

const Index = ({ data }) => (
  <>
    <Layout>
      <HomepageBox>
        <WarpLogo data={data.homeJson.logo} />
        <Title as="h1" size="large">
          {data.homeJson.content_intro.childMarkdownRemark.rawMarkdownBody}
        </Title>
      </HomepageBox>
      <Gallery items={data.homeJson.gallery} />
    </Layout>
    <Footer />
  </>
);

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query HomepageQuery {
    homeJson {
      title
      content_intro {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      gallery {
        title
        copy
        link
        image {
          childImageSharp {
            fluid(maxHeight: 200, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      logo {
        title
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
          extension
          publicURL
        }
      }
    }
  }
`;
